<template>
  <div id="mfa-card-wrapper" class="card flex flex-wrap justify-center px-6 gap-6 mb-8">
    <h3 class="text-2xl font-black">Log in</h3>
    <div class="icon-field-row w-full">
      <IconField>
        <InputIcon class="pi pi-envelope"/>
        <InputText v-model="email" placeholder="Email address" class="w-full"/>
      </IconField>
      <Message v-if="showEmailErrorMessage" severity="error" class="text-sm mt-1"> {{emailErrorMessage}} </Message>
    </div>
    <div class="icon-field-row w-full">
      <IconField>
        <InputIcon class="pi pi-lock z-10"/>
        <Password class="w-full [&_input]:pl-10 [&_input]:w-full" v-model="password"
                  placeholder="Password"
                  :feedback="false" toggleMask>
        </Password>
      </IconField>
      <Message v-if="showPasswordErrorMessage" severity="error" class="text-sm mt-1"> {{passwordErrorMessage}} </Message>
    </div>
    <div class="text-left w-full">
      <Button class="pl-0" label="Forgot Password?" @click="forgetPassword" link/>
    </div>
    <div class="w-full">
      <Button class="w-full" label="Log In" @click="login" :loading="loading&&keepLoading"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginCard',
  props: {
    loginStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      email: '',
      password: '',
      showEmailErrorMessage: false,
      showPasswordErrorMessage: false,
      emailErrorMessage: '',
      passwordErrorMessage: '',
      infoMessage: '',
      loading: false,
    };
  },
  watch: {
    email() {
      this.showEmailErrorMessage = false;
      this.emailErrorMessage = '';
    },
    password() {
      this.showPasswordErrorMessage = false;
      this.passwordErrorMessage = '';
    },
  },
  created() {
    this.infoMessage = this.$store.state.showTokenExpiringMessage ?? null;
  },
  beforeRouteLeave() {
    this.$store.commit('setShowTokenExpiringMessage', null);
  },
  computed: {
    keepLoading() {
      //if loginStatus is error, return false
      return this.loginStatus !== 'error';
    }
  },
  methods: {
    login() {
      this.loading = true;

      if (!this.email) {
        this.emailErrorMessage = 'Email is required';
        this.showEmailErrorMessage = true;
        this.loading = false;
        return;
      }

      if (!this.password) {
        this.passwordErrorMessage = 'Password is required';
        this.showPasswordErrorMessage = true;
        this.loading = false;
        return;
      }

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailPattern.test(this.email)) {
        this.emailErrorMessage = 'Invalid email format';
        this.showEmailErrorMessage = true;
        this.loading = false;
        return;
      }
      this.$emit('trigger-login', this.email, this.password);
    },
    forgetPassword() {
      this.$emit('forget-password', this.email);
    },
  },
};
</script>

<style scoped>

</style>