<script setup>
import {computed, inject, ref} from "vue";
import AuthenticationService from "@/services/AuthenticationService";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const currentPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');

const loading = ref(false);

const hasLowerCase = ref(false);
const hasUpperCase = ref(false);
const hasNumeric = ref(false);
const hasSpecialChar = ref(false);
const isLongEnough = ref(false);

const isPasswordValid = computed(() => {
  return hasLowerCase.value && hasUpperCase.value && hasNumeric.value && hasSpecialChar.value && isLongEnough.value;
});

function validatePassword() {
  const password = newPassword.value;

  isLongEnough.value = password.length >= 8;
  hasUpperCase.value = /[A-Z]/.test(password);
  hasLowerCase.value = /[a-z]/.test(password);
  hasSpecialChar.value = /[@$!%*#?&]/.test(password);
  hasNumeric.value = /\d/.test(password);
}

const showToastMessage = inject("showToastMessage");
const store = useStore();
const router = useRouter();

async function confirm() {
  loading.value = true;
  if (newPassword.value !== confirmPassword.value || !newPassword.value || !confirmPassword.value) {
    showToastMessage("error", "Error", "Passwords do not match");
    loading.value = false;
    return;
  }

  const response = await AuthenticationService.changePassword(router, store, currentPassword.value, newPassword.value);
  if (response.status === "error") {
    showToastMessage("error", "Error", response.message);
  } else {
    showToastMessage("success", "Password updated", "Successfully updated your password.");
    currentPassword.value = '';
    newPassword.value = '';
    confirmPassword.value = '';
  }

  loading.value = false;
}

</script>

<template>
  <h1 class="text-3xl font-bold py-8 text-primaryBlue ">User Profile</h1>
  <div class="grid grid-cols-2 text-primaryBlue">
    <Card id="left-card" class="px-4 text-primaryBlue">
      <template #content>
        <h3 class="text-2xl font-black py-1">Change Your Password</h3>
        <div id="current_password" class="w-full px-8 py-1">
          <p class="pt-4">Current Password</p>
          <IconField>
            <InputIcon class="pi pi-lock z-10"/>
            <Password class="w-full [&_input]:pl-10 [&_input]:w-full" v-model="currentPassword"
                      placeholder="Current Password"
                      :feedback="false" toggleMask>
            </Password>
          </IconField>
        </div>

        <div id="new_password" class="icon-field-row w-full px-8 py-1">
          <p class="pt-4 py-1">New Password</p>
          <IconField>
            <InputIcon class="pi pi-lock z-10"/>
            <Password class="w-full [&_input]:pl-10 [&_input]:w-full" v-model="newPassword" placeholder="Password"
                      :feedback="true" @input="validatePassword"
                      strong-regex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])(?=.{8,})" toggleMask>
              <template #header>
                <div class="font-semibold text-xm mb-4">Pick a password</div>
              </template>
              <template #footer>
                <Divider/>
                <ul class="pl-2 ml-2 my-0 leading-normal">
                  <li :class="{ 'text-green-500': hasLowerCase, 'text-red-500': !hasLowerCase }">At least one
                    lowercase
                  </li>
                  <li :class="{ 'text-green-500': hasUpperCase, 'text-red-500': !hasUpperCase }">At least one
                    uppercase
                  </li>
                  <li :class="{ 'text-green-500': hasNumeric, 'text-red-500': !hasNumeric }">At least one numeric</li>
                  <li :class="{ 'text-green-500': hasSpecialChar, 'text-red-500': !hasSpecialChar }">At least one
                    special character
                  </li>
                  <li :class="{ 'text-green-500': isLongEnough, 'text-red-500': !isLongEnough }">Minimum 8 characters
                  </li>
                </ul>
              </template>
            </Password>
          </IconField>
        </div>

        <div id="confirm_password" class="icon-field-row w-full px-8 py-1">
          <p class="pt-4 py-1">Confirm Password</p>
          <IconField>
            <InputIcon class="pi pi-lock z-10"/>
            <Password class="w-full [&_input]:pl-10 [&_input]:w-full" v-model="confirmPassword"
                      placeholder="Confirm Password"
                      :feedback="false" toggleMask>
            </Password>
          </IconField>

          <div class="grid mt-8 w-full">
            <Button label="Confirm" class="px-0 w-full bg-primaryBlue" @click="confirm"
                    :disabled="!isPasswordValid || (loading)" :loading="loading"/>
          </div>
        </div>
      </template>
    </Card>
  </div>

</template>

<style scoped>

</style>