<script setup>
import {computed, defineProps, inject, onMounted, ref, watch} from "vue";
import SchoolService from "@/services/SchoolService";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import StudentService from "@/services/StudentService";

const router = useRouter();
const store = useStore();
// eslint-disable-next-line no-unused-vars
const showToastMessage = inject("showToastMessage");
const redirectUserToFirstAvailableTab = inject("redirectUserToFirstAvailableTab");

const props = defineProps({
  showApiTab: Boolean,
});

const selectedSchool = ref();

const schools = ref([]);

const showSuggestion = computed(() => !!selectedSchool.value);

const studentIdentifier = ref('');
const studentIdentifierLength = computed(() => studentIdentifier.value.length);

const expectedLengths = ref([]);

const loading = ref(false);
const checkResult = ref();



watch(() => selectedSchool.value, async (newVal) => {
  if (newVal) {
    errorMessages.value.school = '';
    checkResult.value = '';
    await loadSchoolLengths();
  }
});

watch(() => studentIdentifier.value, (newVal) => {
  if (newVal) {
    errorMessages.value.identifier = '';
    checkResult.value = '';
  }
});

async function getSchools() {
  const getSchoolNamesResponse = await SchoolService.getSchoolNames(router, store);
  if (getSchoolNamesResponse.status === 'success') {
    schools.value = getSchoolNamesResponse.schools.map(school => {
      return {name: school, value: school}
    });
  } else {
    console.log('Error fetching school names:', getSchoolNamesResponse.message);
  }
}

onMounted(async () => {
  if (!props.showApiTab) {
    redirectUserToFirstAvailableTab();
    return
  }
  await getSchools();
});

const errorMessages = ref({
  school: '',
  identifier: '',
});

async function loadSchoolLengths() {
  const response = await SchoolService.getSchoolIdentifierLengths(router, store, selectedSchool.value.value);
  if (response.status === 'success') {
    expectedLengths.value = response.lengths;
  } else {
    console.log('Error fetching school lengths:', response.message);
  }
}

async function checkEligibility() {
  loading.value = true;
  if (selectedSchool.value === undefined) {
    errorMessages.value.school = 'School is required';
  }

  if (studentIdentifierLength.value === 0) {
    errorMessages.value.identifier = 'Student Identifier is required';
  }

  if (errorMessages.value.school || errorMessages.value.identifier) {
    loading.value = false;
    return;
  }

  const response = await StudentService.checkStudent(router, store, selectedSchool.value.value, studentIdentifier.value);
  if (response.status === 'success') {
    if (response.eligible) {
      checkResult.value = 'Eligible';
    } else {
      checkResult.value = 'Not Eligible';
    }
  } else {
    console.log('Error checking eligibility:', response.message);
  }

  loading.value = false;
}

</script>

<template>
<div v-if="props.showApiTab" >
  <h1 class="text-3xl font-bold py-8 text-primaryBlue ">Eligibility Check</h1>
  <div id="left-card" class="grid grid-cols-2">
    <Card class="px-4">
      <template #content>
        <p class="pt-4">Select School</p>
        <Select v-model="selectedSchool" :options="schools" optionLabel="name" placeholder="Select a School" class="w-full" />
        <Message severity="error" v-if="errorMessages.school"> {{errorMessages.school}} </Message>

        <div class="relative w-full">
          <p class="pt-8">Student Identifier</p>
          <div class="relative">
            <InputText v-model="studentIdentifier" class="w-full pr-20" />
            <div class="absolute top-1/2 transform -translate-y-1/2 right-2">
              <Tag :severity="'warn'" :value="'Length: ' + studentIdentifierLength" class="bg-[#F6DE95] text-[#5E4803]"/>
            </div>
          </div>
          <Message severity="error" v-if="errorMessages.identifier"> {{errorMessages.identifier}} </Message>
        </div>

        <div class="py-4">
          <p v-show="showSuggestion&&expectedLengths.length > 0" class="text-sm">Expected length is
            {{ expectedLengths.join(' or ') }} characters</p>
        </div>

        <div class="flex mt-8">
          <Button label="Check" @click="checkEligibility" :loading="loading" class="bg-primaryBlue w-[130px]" />
          <div class="flex-grow w-full px-2" v-if="checkResult">
            <Message severity="success" v-if="checkResult === 'Eligible'">{{checkResult}}</Message>
            <Message severity="error" v-if="checkResult !== 'Eligible'">{{checkResult}}</Message>
          </div>
        </div>


      </template>
    </Card>
  </div>

</div>
</template>

<style scoped>

</style>