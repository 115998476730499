
<template>
  <div id="otnotice-29c16aaa-e890-4b3e-bd38-d8e0ab72060b" class="otnotice mt-4"></div>
</template>

<script>
export default {
  mounted() {
    // Load OneTrust privacy notice
    const script1 = document.createElement('script');
    script1.src = 'https://privacyportal-ca-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
    script1.setAttribute('type', 'text/javascript');
    script1.setAttribute('charset', 'UTF-8');
    script1.setAttribute('id', 'otprivacy-notice-script');
    script1.setAttribute('settings', 'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1jYS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9');
    script1.onload = () => {
      const script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.charset = 'UTF-8';
      script2.text = `
        // To ensure external settings are loaded, use the Initialized promise:
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-ca-cdn.onetrust.com/96c2069e-5f80-46fc-b63d-df375e98b318/privacy-notices/29c16aaa-e890-4b3e-bd38-d8e0ab72060b.json"]);
        });
      `;
      document.body.appendChild(script2);
    };
    document.body.appendChild(script1);
  }
};
</script>

<style scoped>

</style>