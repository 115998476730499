<script setup>
import {inject, defineProps, ref, onMounted, watch} from "vue";
import schoolService from "@/services/SchoolService";
import accountService from "@/services/AccountService";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import ProgressSpinner from 'primevue/progressspinner';


const store = useStore();
const router = useRouter();

// Inject the handleNotice function provided by the parent
const showToastMessage = inject("showToastMessage");
const redirectUserToFirstAvailableTab = inject("redirectUserToFirstAvailableTab");

if (!showToastMessage) {
  console.error("showToastMessage is not provided");
}

const props = defineProps({
  showDashboardTab: Boolean,
  showSchoolsTab: Boolean,
  showApiTab: Boolean,
  showUsersTab: Boolean,
  showDocsTab: Boolean,
  showUploadTab: Boolean,
});

const schoolCount = ref(0);
const accountCount = ref(0);
const countReady = ref(false);

async function loadCounts() {
  const [schoolResponse, accountResponse] = await Promise.all([
    schoolService.countSchools(router, store),
    accountService.countAccounts(router, store)
  ]);

  if (schoolResponse.status === 'success') {
    schoolCount.value = schoolResponse.count;
    console.log('School count loaded', schoolCount.value);
  } else {
    console.error('Error fetching school count:', schoolResponse.message);
  }

  if (accountResponse.status === 'success') {
    accountCount.value = accountResponse.count;
    console.log('Account count loaded', accountCount.value);
  } else {
    console.error('Error fetching account count:', accountResponse.message);
  }

  countReady.value = schoolCount.value !== null && accountCount.value !== null;
}

watch(() => props.showDashboardTab, async (newVal) => {
  console.log('Dashboard tab changed', newVal);
  if (newVal && !countReady.value) {
    console.log('Loading counts in watch');
    await loadCounts();
  }
});

onMounted(async () => {
  console.log('DashboardPage beforemount');
  if (!props.showDashboardTab) {
    console.log('Redirecting user to first available tab');
    redirectUserToFirstAvailableTab();
  }

  if (props.showDashboardTab && !countReady.value) {
    console.log('Loading counts in mounted');
    await loadCounts();
  }
});

</script>

<template>
  <div v-if="props.showDashboardTab">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 py-4 text-red-600">
      <Card v-if="showSchoolsTab" id="schools" class="text-primaryBlue cursor-pointer" @click="$router.push('/schools')" >
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl  h-[66px]">
            <div>
              <h2>Schools</h2>
              <div v-if="countReady">
                <p class="text-4xl font-bold">{{schoolCount}}</p>
              </div>
              <div v-else>
                <ProgressSpinner style="width: 40px; height: 40px" strokeWidth="8" fill="transparent"
                                 animationDuration="1.5s" aria-label="Custom ProgressSpinner" />
              </div>
            </div>
            <i class="pi pi-building justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>
      <Card v-if="showUsersTab" id="users" class="text-primaryBlue cursor-pointer" @click="$router.push('/users')">
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl h-[66px]">
            <div>
              <h2>Users</h2>
              <div v-if="countReady">
                <p class="text-4xl font-bold">{{accountCount}}</p>
              </div>
              <div v-else>
                <ProgressSpinner style="width: 40px; height: 40px" strokeWidth="8" fill="transparent"
                                 animationDuration="1.5s" aria-label="Custom ProgressSpinner" />
              </div>
            </div>
            <i class="pi pi-fw pi-users justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>
      <Card v-if="showUploadTab" id="upload" class="text-primaryBlue cursor-pointer" @click="$router.push('/upload')">
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl h-[66px]">
            <div class="flex flex-col justify-center h-full">
              <h2>Upload</h2>
            </div>
            <i class="pi pi-upload justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>
      <Card v-if="showApiTab" id="eligibility-check" class="text-primaryBlue cursor-pointer" @click="$router.push('/eligibility-check')">
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl h-[66px]">
            <div class="flex flex-col justify-center h-full">
              <h2>Eligibility Check</h2>
            </div>
            <i class="pi pi-verified justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>
      <Card v-if="showDocsTab" id="docs" class="text-primaryBlue cursor-pointer" @click="$router.push('/docs')">
        <template #content>
          <div class="grid grid-cols-2 items-center px-4  text-xl h-[66px]">
            <div class="flex flex-col justify-center h-full">
              <h2>API Documentation</h2>
            </div>
            <i class="pi pi-file justify-self-end text-4xl"></i>
          </div>
        </template>
      </Card>

    </div>
  </div>
</template>

<style scoped>

</style>