<script setup>
import {ref, defineProps} from 'vue';

const props = defineProps({
  events: {
    type: Array,
    required: true,
  },
});

const visible = ref(false);
const selectedEvent = ref(null);

const openDialog = (event) => {
  selectedEvent.value = event;
  visible.value = true;
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString(undefined, {
    timeZoneName: 'short', // Includes timezone abbreviation (e.g., PST, EDT)
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true // Change to false for 24-hour format
  });
};
</script>

<template>
  <div class="card activity-log-card">
    <Timeline :value="props.events">
      <template #marker>
        <span class="flex w-8 h-8 items-center justify-center rounded-full z-10 shadow-sm">
            <i class="pi pi-circle text-primaryBlue"></i>
        </span>
      </template>
      <template #content="slotProps">
        <div class="activity-log-item mb-2" style="margin-top: 3px">
          <div class="upper-log font-black text-black">
            <a href="#" @click.prevent="openDialog(slotProps.item)" class="text-primaryBlue hover:underline">
              {{ slotProps.item.description }}
            </a>
          </div>

          <div class="middle-log">
            <div>
              <small>
                @ {{ formatDate(slotProps.item.logged_at_original) }}
              </small>
            </div>


            <span
                v-tooltip="{ value: slotProps.item.causerEmail }"
                class="font-black text-black text-sm"
            >
              by {{ slotProps.item.causerName }}
            </span>
          </div>
        </div>
      </template>
    </Timeline>

    <Dialog
        v-model:visible="visible"
        modal
        draggable
        header="Activity Detail"
        :style="{ width: '30rem' }"
        :pt="{
    root: 'bg-gray-50 shadow-2xl rounded-lg',
    content: 'p-6'
  }"
        class="pb-4"
    >
      <template v-if="selectedEvent">
        <div class="space-y-4">
          <div class="bg-white shadow rounded-lg p-4">
            <div class="flex items-center">
              <i class="pi pi-user mr-3 text-blue-500"></i>
              <div>
                <p class="font-semibold text-gray-800">{{ selectedEvent.causerName }}</p>
                <p class="text-sm text-gray-500">{{ selectedEvent.causerEmail }}</p>
              </div>
            </div>
          </div>

          <div class="bg-white shadow rounded-lg p-4 flex items-center">
            <i class="pi pi-calendar mr-3 text-green-500"></i>
            <div>
              <p class="font-semibold text-gray-800"> Date and Time </p>
              <p class="text-sm text-gray-500">{{ formatDate(selectedEvent.logged_at_original) }}</p>
            </div>
          </div>

          <div class="bg-white shadow rounded-lg p-4 flex items-center">
            <i class="pi pi-info-circle mr-3 text-purple-500"></i>
            <div>
              <p class="font-semibold text-gray-800">Action</p>
              <p class="text-sm text-gray-500">{{ selectedEvent.description }}</p>
            </div>
          </div>

          <div v-if="selectedEvent.label" class="bg-white shadow rounded-lg p-4 flex items-center">
            <i class="pi pi-tag mr-3 text-purple-500"></i>
            <div>
              <p class="font-semibold text-gray-800">Name</p>
              <p class="text-sm text-gray-500">{{ selectedEvent.label }}</p>
            </div>
          </div>


          <div
              v-if="selectedEvent.multiple && selectedEvent.multiple !== 'both'"
              class="bg-white shadow rounded-lg p-4 flex items-center"
          >
            <i class="pi pi-file mr-3 text-orange-500"></i>
            <div>
              <p class="font-semibold text-gray-800">Detail</p>
              <p class="text-sm text-gray-500">
                {{ selectedEvent.multiple }} updated
              </p>
            </div>
          </div>

          <div
              v-if="selectedEvent.from"
              class="bg-white shadow rounded-lg p-4 flex items-center"
          >
            <i class="pi pi-arrow-left mr-3 text-red-500"></i>
            <div>
              <p class="font-semibold text-gray-800">Changed From</p>
              <p class="text-sm text-gray-500">{{ selectedEvent.from }}</p>
            </div>
          </div>

          <div
              v-if="selectedEvent.to"
              class="bg-white shadow rounded-lg p-4 flex items-center"
          >
            <i class="pi pi-arrow-right mr-3 text-teal-500"></i>
            <div>
              <p class="font-semibold text-gray-800">Changed To</p>
              <p class="text-sm text-gray-500">{{ selectedEvent.to }}</p>
            </div>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<style scoped>
.activity-log-card :deep(.p-timeline-event-opposite) {
  display: none;
}

.activity-log-card :deep(.p-timeline-event) {
  margin-left: 16px;
}
</style>