<script setup>

import UnauthenticatedLayout from "@/layouts/UnauthenticatedLayout.vue";
import {ref, watch} from "vue";
import {useRouter} from "vue-router";
import {useToast} from "primevue/usetoast";
import {useStore} from "vuex";
import AuthenticationService from "@/services/AuthenticationService";


// eslint-disable-next-line no-unused-vars
const store = useStore();
const router = useRouter();
const toast = useToast();
const disabledVerify = ref(true);

const email = ref('');
const confirmationCode = ref('');
const loading = ref(false);
const resendClickable = ref(true);
const errorMessage = ref({
  email: '',
  confirmationCode: ''
});

watch(email, (newValue) => {
  errorMessage.value.email = '';
  disabledVerify.value = !newValue || confirmationCode.value.length < 6;
});

watch(confirmationCode, (newValue) => {
  errorMessage.value.confirmationCode = '';
  disabledVerify.value = newValue.length < 6 || !email.value;
});


const cancelVerification = () => {
  router.push({name: 'login'});
};

const resendCode = async () => {
  console.log('resendCode');
  resendClickable.value = false;
  //check email is filled
  if (!email.value) {
    errorMessage.value.email = 'Email is required';
    resendClickable.value = true;
    return;
  }

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailPattern.test(email.value)) {
    errorMessage.value.email = 'Invalid email address';
    resendClickable.value = true;
    return;
  }

  const resendCodeResponse = await AuthenticationService.resendConfirmationCode(email.value);
  if (resendCodeResponse.status === 'success') {
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Code has been sent to your email',
      life: 3000
    });
  } else {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: resendCodeResponse.message,
      life: 3000
    });
  }

  resendClickable.value = true;
};

const verifyCode = async () => {
  loading.value = true;
  //check email is filled
  if (!email.value) {
    errorMessage.value.email = 'Email is required';
    return;
  }

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailPattern.test(email.value)) {
    errorMessage.value.email = 'Invalid email address';
    return;
  }

  //check confirmation code is filled
  if (!confirmationCode.value) {
    errorMessage.value.confirmationCode = 'Confirmation code is required';
    return;
  }

  if (confirmationCode.value.length !== 6) {
    errorMessage.value.confirmationCode = 'Confirmation code must be 6 digits';
    return;
  }

  loading.value = true;
  const verifyCodeResponse = await AuthenticationService.verifyConfirmationCode(email.value, confirmationCode.value);
  if (verifyCodeResponse.status === 'success') {
    store.commit('setUserToken', 'verified');
    await router.push({name: 'login'});
  } else {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: verifyCodeResponse.message,
      life: 3000
    });
  }

  loading.value = false;
};



</script>

<template>
  <main>
    <UnauthenticatedLayout>
      <Toast />
      <div id="login-card-container" class="m-0 mx-auto max-w-[420px] grid justify-center items-center text-center">
        <Card id="login-card" class="bg-white bg-opacity-95 pb-8">
          <template #content>
            <img id="logo" class="mx-auto w-4/5" alt="user header" src="/images/GM_Digital_Doctor_Logo_RGB_150dpi.png"/>
            <div id="mfa-card-wrapper" class="mx-auto grid gap-6">
              <h3 class="text-2xl font-black">Verify Registration</h3>

              <div class="icon-field-row w-full px-6">
                <IconField>
                  <InputIcon class="pi pi-envelope"/>
                  <InputText v-model="email" placeholder="Email address" class="w-full"/>
                </IconField>
                <Message v-if="errorMessage.email" severity="error" class="text-sm mt-1"> {{ errorMessage.email }}</Message>
              </div>

              <div class="px-6">
                <div class="flex space-x-2 mb-6">
                  <InputOtp v-model="confirmationCode" :length="6" style="gap: 8px" class="w-full">
                    <template #default="{ attrs, events }">
                      <input type="text" v-bind="attrs" v-on="events"
                             class="h-12 w-12 text-center text-xl border border-gray-300 rounded"
                             autocomplete="one-time-code"/>
                    </template>
                  </InputOtp>
                </div>
                <div>
                  <Message v-if="errorMessage.confirmationCode" severity="error" class="text-sm mt-1 bg-transparent"> {{ errorMessage.confirmationCode }}</Message>
                </div>

                <div class="mt-4">
                  <span class="text-blue-900 mr-2 font-bold">Didn't receive a code?</span>
                  <a @click.prevent="resendCode"
                     class="font-bold text-blue-900 cursor-pointer hover:underline"
                     :class="{ 'cursor-not-allowed text-gray-400': (!resendClickable&&loading) }"
                     :style="{ pointerEvents: resendClickable&&!loading ? 'auto' : 'none', cursor: resendClickable&&!loading ? 'pointer' : 'none' }">
                    Resend
                  </a>
                </div>
              </div>
              <div class="grid gap-6 px-6 grid-cols-2 mt-8">
                <Button label="Cancel" @click="cancelVerification" outlined/>
                <Button label="Verify" @click="verifyCode" :disabled="disabledVerify" :loading="loading"/>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </UnauthenticatedLayout>
  </main>
</template>

<style scoped>

</style>