<script setup>

import {onMounted, ref, defineProps, defineEmits} from 'vue';
import {useToast} from "primevue/usetoast";

const methods = [
  {key: 'email', name: 'Email'},
  {key: 'sms', name: 'SMS'},
];

const maskedPhoneNumber = ref('416-***-1234');
const maskedEmail = ref('test@gmail.com');
const loading = ref(false);

const selectedMethod = ref('');
const toast = useToast();

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
  phoneNumber: {
    type: String,
    required: true,
  },
});

onMounted(() => {
  selectedMethod.value = methods[0].name;
  maskedEmail.value = props.email.replace(/(.).*(?=@)/, '$1***');
  maskedPhoneNumber.value = props.phoneNumber.replace(/(\+\d{1,3})?(\d{2})(\d{4})(\d{4})/, '$1$2****$4');
});

// Define the emit function
const emit = defineEmits([
    'cancel-method',
    'confirm-method'
]);

// Define the cancelMethod function
const cancelMethod = () => {
  emit('cancel-method');
};

const confirmMethod = () => {
  if(selectedMethod.value === 'SMS') {
   //toast to show that this method is not supported yet
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'This method is not supported yet',
      life: 3000
    });
  } else {
    loading.value = true;
    emit('confirm-method');
  }
};

</script>

<template>
  <div id="mfa-card-wrapper" class="">
    <Toast/>
    <h3 class="text-2xl font-black">Get verification code</h3>
    <div class="icon-field-row w-full text-gray-500">
      <div class="flex my-6 pl-4">
        <div class="flex items-center">
          <RadioButton v-model="selectedMethod" inputId="sms" name="mfaMethod" value="SMS" disabled/>
        </div>

        <div class="flex flex-col justify-center pl-4">
          <div class="text-base text-gray-500 text-left">
            <label for="sms" class="text-base font-normal">Text Message</label>
          </div>
          <div class="mt-1">
            {{ maskedPhoneNumber }}
          </div>
        </div>
      </div>

      <div class="flex my-6 pl-4">
        <div class="flex items-center">
          <RadioButton v-model="selectedMethod" inputId="email" name="mfaMethod" value="Email"/>
        </div>

        <div class="flex flex-col justify-center pl-4">
          <div class="text-base text-gray-500 text-left">
            <label for="sms" class="">Email</label>
          </div>
          <div class="mt-1">
            {{ maskedEmail }}
          </div>
        </div>
      </div>
    </div>
    <div class="grid gap-6 px-6 grid-cols-2 mt-8">
      <Button label="Cancel" @click="cancelMethod" outlined/>
      <Button label="Continue" @click="confirmMethod" :disabled="loading"
              :loading="loading"/>
    </div>
  </div>
</template>

<style scoped>

</style>