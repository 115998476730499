import axios from "axios";
import tokenService from "@/services/TokenService";

const StudentService = {
    checkStudent: async (router, store, schoolName, studentIdentifier) => {
        const config = tokenService.getConfig(router, store);
        return await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/check-student', {
            studentIdentifier: studentIdentifier,
            schoolName: schoolName
        }, config)
            .then(response => {
                console.log('StudentService:', response.data)
                return {
                    status: 'success',
                    eligible: response.data.eligible,
                    message: response.data.message
                };
            })
            .catch(error => {
                console.error('Error loading student:', error.response || error);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });
    },
};

export default StudentService;