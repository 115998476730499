<script>
export default {
  name: 'ForgetPasswordCard',
  props: {
    sendResetCodeStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      showErrorMessage: false,
      errorMessage: '',
    };
  },
  computed: {
    loading() {
      return this.sendResetCodeStatus === 'pending';
    }
  },
  watch: {
    email() {
      this.showErrorMessage = false;
    },
    sendResetCodeStatus(newStatus) {
      this.loading = newStatus === 'pending';
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel-forget-password');
    },
    requestPasswordCode() {
      this.loading = true;
      // Validate email
      if (!this.email) {
        this.errorMessage = 'Email is required';
        this.showErrorMessage = true;
        this.loading = false;
        return;
      }

      //validate email format
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailPattern.test(this.email)) {
        this.errorMessage = 'Invalid email format';
        this.showErrorMessage = true;
        this.loading = false;
        return;
      }

      this.$emit('request-reset-password-code', this.email);
    }
  },
};
</script>

<template>
  <div id="mfa-card-wrapper" class="card flex flex-wrap justify-center px-6 gap-6 mb-8">
    <h3 class="text-2xl font-black">Forgot Password</h3>
    <div class="icon-field-row w-full">
      <IconField>
        <InputIcon class="pi pi-envelope"/>
        <InputText v-model="email" placeholder="Email address" class="w-full"/>
      </IconField>
      <Message v-if="showErrorMessage" severity="error" class="text-sm mt-1"> {{ errorMessage }}</Message>
    </div>
  </div>
  <div class="grid gap-6 px-6 grid-cols-2 mt-8">
    <Button label="Cancel" @click="cancel" outlined/>
    <Button label="Continue" @click="requestPasswordCode" class="px-0.5" :loading="loading"/>
  </div>
</template>

<style scoped>

</style>